<template>
    <div>
        <div class="container-form">
            <form action="">
                <div class="container-codigo">
                    <div class="label label-codigo">
                        <span class="codigo"> Código de barras <span class="obrigatorio">*</span> </span>
                        <tooltip :isBottom="true" :msg="msgCodigo"></tooltip>
                    </div>
                    <div class="input input-codigo">
                        <input
                            type="number"
                            autocorrect="off"
                            min="13"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="9999999999999"
                            maxlength="13"
                            placeholder="0000000000000"
                            v-model="codigo"
                            @input="validaCodigo"
                            id="inputCodigo"
                            required
                        />
                    </div>
                    <div v-if="codigoIsInvalid" class="mensagemErro">Informação Obrigatória</div>
                </div>
                <div class="container container-descricao" v-show="descricao != '' && codigo != ''">
                    <div class="label label-descricao">
                        <span class="descricao"> Descrição </span>
                    </div>
                    <div class="input input-descricao">
                        <input v-model="descricao" type="text" placeholder="" disabled />
                    </div>
                </div>
                <div class="container">
                    <div class="label">
                        <span> Corredor <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input">
                        <input
                            v-model="modulo"
                            type="text"
                            autocorrect="off"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            maxlength="50"
                            placeholder="Preencha o corredor"
                            id="inputModulo"
                            @input="validaModulo"
                        />
                    </div>
                    <div v-if="moduloIsInvalid.mensagem" class="mensagemErro">{{ moduloIsInvalid.mensagem }}</div>
                </div>
                <div class="containerQV">
                    <div class="container container-validade">
                        <div class="label label-validade">
                            <span class="validade"> Data de validade <span class="obrigatorio">*</span> </span>
                            <tooltip :isTop="true" :msg="msgValidade"></tooltip>
                        </div>
                        <div class="input input-validade">
                            <input
                                type="date"
                                autocorrect="off"
                                autocomplete="off"
                                :min="dataAtual()"
                                :max="dataMax()"
                                placeholder="dd/mm/aaaa"
                                v-model="validade"
                                ref="dataValidade"
                                @Change="checkValidade(validade)"
                                required
                            />
                        </div>
                        <div class="msg-validade" v-show="validade != '' && geraRebaixa">
                            Data muito curta, o produto não será enviado para rebaixa.
                        </div>
                    </div>
                    <div class="container container-quantidade">
                        <div class="label label-quantidade">
                            <span class="quantidade"> Quantidade <span class="obrigatorio">*</span> </span>
                            <tooltip class="tooltip-qnt" :isLeft="true" :msg="msgQnt"></tooltip>
                        </div>
                        <div class="input input-quantidade">
                            <input
                                type="number"
                                min="0"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                max="999999"
                                maxlength="6"
                                placeholder="000000"
                                v-model="quantidade"
                                @input="validaQuantidade"
                                required
                            />

                            <div class="controle">
                                <button class="btn-minus" type="button" @click="decrementQuantidade">
                                    <img src="../assets/img/minus.svg" alt="subtrair" />
                                </button>
                                <button class="btn-plus" type="button" @click="incrementQuantidade">
                                    <img src="../assets/img/plus.svg" alt="adicionar" />
                                </button>
                            </div>
                        </div>
                        <div v-if="quantidadeIsInvalid" class="mensagemErro">Informação Obrigatória</div>
                    </div>
                </div>

                <transition-group tag="div" name="slide-both">
                    <template v-for="(element, index) in validadesAdicionais" :key="index">
                        <div class="containerQV repeatQV" v-if="element.validade != validade">
                            <div class="container container-validade">
                                <div class="label label-validade">
                                    <span class="validade"> Data de validade <span class="obrigatorio">*</span> </span>
                                </div>
                                <div class="input input-validade">
                                    <input
                                        type="date"
                                        autocorrect="off"
                                        autocomplete="off"
                                        :min="dataAtual()"
                                        :max="dataMax()"
                                        placeholder="dd/mm/aaaa"
                                        v-model="element.validade"
                                        ref="dataValidade"
                                        @Change="checkValidade(element.validade, index)"
                                        :onBlur="removeDuplicado"
                                        required
                                    />
                                </div>
                                <div class="msg-validade" v-if="element.geraRebaixa">
                                    Data muito curta, o produto não será enviado para rebaixa.
                                </div>
                            </div>
                            <div class="container container-quantidade">
                                <div class="label label-quantidade">
                                    <span class="quantidade"> Quantidade <span class="obrigatorio">*</span> </span>
                                    <button class="remove-validade" type="button" @click="removeValidade(index)">
                                        <img src="../assets/img/close-white.svg" alt="remover validade" />
                                    </button>
                                </div>
                                <div class="input input-quantidade">
                                    <input
                                        type="number"
                                        min="0"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        max="999999"
                                        maxlength="6"
                                        placeholder="000000"
                                        v-model="element.quantidade"
                                        required
                                    />

                                    <div class="controle">
                                        <button
                                            class="btn-minus"
                                            type="button"
                                            @click="if (element.quantidade-- <= 0) element.quantidade = 0;"
                                        >
                                            <img src="../assets/img/minus.svg" alt="subtrair" />
                                        </button>
                                        <button
                                            class="btn-plus"
                                            type="button"
                                            @click="if (element.quantidade++ >= 99999) element.quantidade = 99999;"
                                        >
                                            <img src="../assets/img/plus.svg" alt="adicionar" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </transition-group>
                <div v-if="dataIsInvalid.valida" class="mensagemErro">{{ dataIsInvalid.mensagem }}</div>
                <div v-if="dataAdicionalIsInvalid.valida" class="mensagemErro">
                    {{ dataAdicionalIsInvalid.mensagem }}
                </div>

                <div class="nova-validade">
                    <div class="adc-validade" @click="adicionaValidade">
                        <img src="../assets/img/plus.svg" alt="adicionar validade" />
                        <h3>Adicionar Validade</h3>
                    </div>
                </div>

                <div v-show="showProduct" class="container container-produto">
                    <div class="label label-produto">
                        <span class="produto"> Produto </span>
                    </div>
                    <div class="produto-img">
                        <img id="imagemProduto" src="" @error="replaceByDefault" alt="produto 1" />
                    </div>
                </div>
            </form>
        </div>

        <footer-save :isSending="isSending" @editaInclusao="editaInclusao" @envia-form="enviaForm"></footer-save>

        <transition name="fade">
            <div v-if="showConfirm" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Descartar alterações?
                        <div class="close-cancel" @click="showConfirm = false">
                            <img src="../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <h3>{{ msgConfirm }}</h3>
                    <div class="cancel-btn">
                        <button
                            @click="
                                closeModal;
                                showConfirm = false;
                            "
                            type="button"
                        >
                            Não
                        </button>
                        <button
                            @click="
                                descarteProduto();
                                limpaCampos();
                            "
                            type="button"
                        >
                            Descartar
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import FooterSave from '../components/FooterSave';
import Tooltip from '../components/Tooltip';
import HTTP from '../http/http.js';
import MsgSucess from '../components/MsgSucess';
import MsgError from '../components/MsgError';
import img from '../assets/img/broken-image.svg';
import amplitude from 'amplitude-js';

export default {
    data() {
        return {
            codigo: '',
            validade: '',
            quantidade: '',
            descricao: '',
            modulo: '',
            moduloFinal: '',
            showProduct: false,
            showSucess: false,
            showAlert: false,
            codigoIsInvalid: false,
            dataIsInvalid: {
                mensagem: '',
                valida: false,
            },
            dataAdicionalIsInvalid: {
                mensagem: '',
                valida: false,
            },
            quantidadeIsInvalid: false,
            isSending: false,
            categoria: '',
            showValidade: false,
            showQuantidade: false,
            showConfirm: false,
            geraRebaixa: false,
            validadesAdicionais: [],
            msgCodigo:
                'Número de até 13 dígitos representado por barras verticais usado para a identificação do produto, use a câmera para escanear o código.',
            msgValidade:
                'Validades existentes do produto a ser incluído, podem existir mais de 1 data de validade para o mesmo produto.',
            msgQnt: 'Quantidade existente do produto a ser incluído na data de validade informada.',
            msgS: 'Produto incluido com sucesso',
            msgE: '',
            recognition: null,
            msgConfirm: '',
            moduloIsInvalid: {
                mensagem: '',
                valida: false,
            },
        };
    },

    components: {
        FooterSave,
        Tooltip,
        MsgSucess,
        MsgError,
    },

    computed: {
        getProduto() {
            return this.$store.getters.getProduto;
        },
        getTodosProdutos() {
            return this.$store.getters.getTodosProdutos;
        },
        getCodigoInclusao() {
            return this.$store.getters.getCodigoInclusao;
        },
        getModuloInclusao() {
            return this.$store.getters.getModuloInclusao;
        },
        getTipoAlerta() {
            return this.$store.getters.getTipoAlerta;
        },
        getTarefas() {
            return this.$store.getters.getTarefas;
        },
    },

    mounted() {
        if (this.getCodigoInclusao) {
            this.codigo = this.getCodigoInclusao;

            this.getDataProduct();
        }

        if (this.getModuloInclusao) {
            this.modulo = this.getModuloInclusao;
        }

        let input = document.getElementById('inputCodigo');
        input.focus();
        input.select();
    },

    methods: {
        adicionaValidade() {
            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
            let event = 'Botão Adicionar Validade';
            amplitude.getInstance().logEvent(event);

            this.validadesAdicionais.push({
                quantidade: 0,
                validade: '',
            });
        },

        removeValidade(currentIndex) {
            this.validadesAdicionais.splice(
                this.validadesAdicionais.findIndex((a, index) => index == currentIndex),
                1
            );
        },

        checkValidade(validade, index) {
            let data2 = new Date();
            let dia = String(data2.getDate()).padStart(2, '0');
            let dia2 = String(data2.getDate() + 2).padStart(2, '0');
            let mes2 = String(data2.getMonth() + 1).padStart(2, '0');
            let ano2 = data2.getFullYear();
            let dataHoje2 = ano2 + '-' + mes2 + '-' + dia2;
            let dataHoje = ano2 + '-' + mes2 + '-' + dia;

            if (index >= 0) {
                if (validade != '' && dataHoje2 >= validade) {
                    this.validadesAdicionais[index]['geraRebaixa'] = true;
                } else {
                    this.validadesAdicionais[index]['geraRebaixa'] = false;
                }
            } else {
                this.geraRebaixa = dataHoje2 >= validade;
            }

            if (index == null) {
                if (validade < dataHoje || validade == '' || validade == null) {
                    this.dataIsInvalid = {
                        mensagem: 'A validade mínima deve ser a data atual.',
                        valida: true,
                    };
                } else {
                    this.dataIsInvalid = {
                        mensagem: '',
                        valida: false,
                    };
                }
            } else {
                if (validade < dataHoje || validade == '' || validade == null) {
                    this.dataAdicionalIsInvalid = {
                        mensagem: `A validade adicional número ${index + 1} está vazia ou incorreta.`,
                        valida: true,
                    };
                    return true;
                } else {
                    this.dataAdicionalIsInvalid = {
                        mensagem: '',
                        valida: false,
                    };
                    return false;
                }
            }
        },

        removeDuplicado() {
            this.validadesAdicionais = this.getUniqueListBy(this.validadesAdicionais, 'validade');
        },

        getDataProduct() {
            let todosProdutos = this.getTodosProdutos;

            let produtoFiltrado = todosProdutos.filter((element) => element.codigo_de_barras == this.codigo);

            if (produtoFiltrado.length == 0) {
                this.descricao = '';
                this.showProduct = false;
                return;
            }

            if (produtoFiltrado[0] != '') {
                this.categoria = produtoFiltrado[0].categoria_2;
                this.descricao = produtoFiltrado[0].descricao;
                this.showProduct = true;

                if (this.codigo) {
                    let img = document.getElementById('imagemProduto');
                    img.setAttribute(
                        'src',
                        'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' + this.codigo + '.webp'
                    );
                }
            } else {
                this.descricao = '';
            }
        },

        dataFormatada(diaDB) {
            if (diaDB != '') {
                let dateSplit = diaDB.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        getUniqueListBy(arr, key) {
            return [...new Map(arr.map((item) => [item[key], item])).values()];
        },

        enviaForm() {
            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
            let event = 'Salvar Inclusão';
            amplitude.getInstance().logEvent(event);

            if (!this.verificaValidade()) return;
            if (!this.validaFormulario()) return;

            this.$store.dispatch('setCodigo', this.codigo);

            this.isSending = true;

            this.validadesAdicionais.push({
                quantidade: this.quantidade,
                validade: this.validade,
            });

            let validades = this.validadesAdicionais;
            let produto = this.getProduto;

            let hasValidade = false;

            if (produto) {
                validades.forEach((element) => {
                    produto.validadesAdicionais.forEach((target) => {
                        if (element.validade == target.validade) {
                            this.msgE = `Ops, a validade ${this.dataFormatada(
                                element.validade
                            )} já foi cadastrada. Caso queira alterar a quantidade, altere na descrição do produto.`;
                            this.showAlert = true;
                            hasValidade = true;
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 3000);
                            return;
                        }
                    });
                });
            }

            this.validadesAdicionais = this.getUniqueListBy(validades, 'validade');
            let usuario = sessionStorage.getItem('usuario');

            if (hasValidade) {
                this.isSending = false;
                return;
            }

            let data = {
                codigo_de_barras: this.codigo,
                descricao: this.descricao,
                validadesAdicionais: this.validadesAdicionais,
                tipo_alerta: this.getTipoAlerta == 5 ? 4 : this.getTipoAlerta,
                modulo: this.moduloFinal,
                usuario: usuario,
            };

            let dataVuex = {
                categoria: this.categoria,
                codigo_de_barras: this.codigo,
                descricao: this.descricao,
                validadesAdicionais: this.validadesAdicionais,
                tipo_alerta: this.getTipoAlerta == 5 ? 4 : this.getTipoAlerta,
                modulo: this.moduloFinal,
            };

            let alerta = this.getTipoAlerta;

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.post(`/condor/${loja}/tarefas-inclusao`, data)
                    .then(() => {
                        this.isSending = false;
                        this.showSucess = true;

                        this.$store.dispatch('setNovoProduto', dataVuex);

                        if (alerta == 5) {
                            let codigo = this.codigo;

                            const itemToBeRemoved = { codigo, validade: '' };

                            let todasTarefas = this.getTarefas;

                            todasTarefas.splice(
                                todasTarefas.findIndex(
                                    (a) =>
                                        a.codigo_de_barras == itemToBeRemoved.codigo &&
                                        a.data_validade == itemToBeRemoved.validade
                                ),
                                1
                            );
                        }

                        setTimeout(() => {
                            this.showSucess = false;
                            this.$router.go(-1);
                        }, 1500);
                    })
                    .catch(() => {
                        // this.isInvalid = true;
                        console.error('erro');
                    });
            } else {
                let produtosSalvos = JSON.parse(localStorage.getItem('inclusao'));

                let dataToLS = null;

                if (produtosSalvos) {
                    produtosSalvos.push(data);

                    dataToLS = JSON.stringify(produtosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.showSucess = true;

                if (alerta == 5) {
                    let codigo = this.codigo;

                    const itemToBeRemoved = { codigo, validade: '' };

                    let todasTarefas = this.getTarefas;

                    todasTarefas.splice(
                        todasTarefas.findIndex(
                            (a) =>
                                a.codigo_de_barras == itemToBeRemoved.codigo &&
                                a.data_validade == itemToBeRemoved.validade
                        ),
                        1
                    );
                }

                localStorage.setItem('inclusao', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                this.isSending = false;
                setTimeout(() => {
                    this.showSucess = false;
                    this.$router.go(-1);
                }, 1500);
            }
        },

        editaInclusao() {
            amplitude.getInstance().init('f5b8a735db8cb839a719abb3cbfe1e85');
            let event = 'Cancelar Inclusão';
            amplitude.getInstance().logEvent(event);

            let alerta = this.getTipoAlerta;

            if (alerta == 5) {
                this.showConfirm = true;
                this.msgConfirm = 'Se a inclusão não for feita, a tarefa continuará sendo exibida.';
            } else {
                this.showConfirm = true;
                this.msgConfirm = 'Todas as alterações serão perdidas';
            }
        },

        descarteProduto() {
            this.$router.go(-1);
        },

        decrementQuantidade() {
            this.quantidade--;
            if (this.quantidade < 0) {
                this.quantidade = 0;
            }
        },

        incrementQuantidade() {
            this.quantidade++;
            if (this.quantidade > 99999) {
                this.quantidade = 99999;
            }
        },

        validaQuantidade() {
            this.quantidadeIsInvalid = this.quantidade < 0 ? true : false;
        },

        validaCodigo() {
            this.codigoIsInvalid = this.codigo == '' ? true : false;
            this.getDataProduct();
        },

        validaModulo() {
            var input = document.querySelector('#inputModulo');
            this.moduloIsInvalid.valida = this.modulo == '' || this.modulo > 100 ? true : false;

            if (this.moduloIsInvalid.valida) {
                this.moduloIsInvalid.mensagem = 'Informação obrigatória';
            } else {
                this.moduloIsInvalid.mensagem = '';
            }

            if (this.modulo.toString().length > input.maxLength) {
                this.modulo = parseFloat(this.modulo.toString().slice(0, input.maxLength));
            }

            // input.addEventListener('keypress', function (e) {
            //     if (!checkChar(e)) {
            //         e.preventDefault();
            //     }
            // });
            // function checkChar(e) {
            //     var char = String.fromCharCode(e.keyCode);

            //     var pattern = '[0-9]';
            //     if (char.match(pattern)) {
            //         return true;
            //     }
            // }
        },

        chooseCorredor(event) {
            this.modulo = event.target.value;
        },

        validaFormulario() {
            this.codigoIsInvalid = this.codigo == '' ? true : false;

            this.checkValidade(this.validade);
            let hasError = false;
            this.validadesAdicionais.forEach((element, index) => {
                if (this.checkValidade(element.validade, index)) {
                    hasError = true;
                    return;
                }
            });
            if (hasError) {
                this.dataAdicionalIsInvalid = {
                    mensagem: `Alguma validade adicional está vazia ou incorreta.`,
                    valida: true,
                };
                return;
            }

            if (this.dataIsInvalid == false) {
                if (this.validade == '') {
                    this.dataIsInvalid = {
                        mensagem: 'Informação Obrigatória',
                        valida: true,
                    };
                } else {
                    this.dataIsInvalid = {
                        mensagem: '',
                        valida: false,
                    };
                }
            } else {
                this.dataIsInvalid == true;
            }

            if (this.modulo == '' || this.modulo == 0) {
                this.moduloIsInvalid = {
                    mensagem: 'O corredor deve ser preenchido',
                    valida: true,
                };
            } else {
                this.moduloFinal = this.modulo;
                this.moduloIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
            }

            this.quantidadeIsInvalid = this.quantidade < 0 || this.quantidade == '' ? true : false;

            if (
                this.codigoIsInvalid == true ||
                this.dataIsInvalid.valida == true ||
                this.dataAdicionalIsInvalid.valida == true ||
                this.quantidadeIsInvalid == true ||
                this.moduloIsInvalid.valida == true
            ) {
                return false;
            }

            return true;
        },

        verificaValidade() {
            let dateInput = new Date(this.validade + ' 00:00');

            let maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() + 2);

            if (dateInput > maxDate) {
                this.dataIsInvalid = {
                    mensagem: 'Data de validade precisa ser menor que 2 anos',
                    valida: true,
                };
                return false;
            }

            return true;
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        dataAtual() {
            let data = new Date();
            let dataHoje = '';
            let dia = String(data.getDate()).padStart(2, '0');
            let mes = String(data.getMonth() + 1).padStart(2, '0');
            let ano = data.getFullYear();
            dataHoje = ano + '-' + mes + '-' + dia;
            return dataHoje;
        },

        dataMax() {
            let data = new Date();
            let dataHoje = '';
            let dia = String(data.getDate()).padStart(2, '0');
            let mes = String(data.getMonth() + 1).padStart(2, '0');
            let ano = data.getFullYear() + 3;
            dataHoje = ano + '-' + mes + '-' + dia;
            return dataHoje;
        },

        limpaCampos() {
            this.codigo = '';
        },

        closeError() {
            this.showAlert = false;
        },
    },

    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('setCodigoInclusao', null);
        this.$store.dispatch('setModuloInclusao', null);
        this.$store.dispatch('setTipoAlerta', 3);

        if (to.name == 'verificacao.validades') {
            this.$router.push({ name: 'alert' });
        } else {
            next();
        }
    },
};
</script>

<style scoped>
.container-form {
    padding: 15px;
    margin-top: 56px;
    margin-bottom: 66px;
    overflow-x: hidden;
}

.adc-validade,
.menos-validade {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #cdcdcd;
    padding: 5px;
    border-radius: 20px;
    background-color: #f4f4f4;
    cursor: pointer;
    transition: all 0.2s;
}

.adc-validade:hover,
.menos-validade:hover {
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #fff;
}

.adc-validade:hover h3,
.menos-validade:hover h3 {
    color: var(--verde);
}

.nova-validade {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
}

.msg-validade {
    font-size: 12px;
    color: var(--vermelho);
}

.nova-validade h3 {
    color: var(--cinza-escuro);
    font-size: 14px;
}

.nova-validade h3 small {
    font-size: 11px;
}

.obrigatorio {
    color: var(--vermelho);
}

form div div span {
    color: var(--cinza-escuro);
}

.produto-img {
    display: flex;
    margin-top: 9px;
    margin-bottom: 15px;
    border: 1px solid #bdc1c6;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.produto-img img {
    max-width: 96%;
    min-width: 100px;
}

input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: var(--verde);
    height: 48px;
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input:disabled {
    background-color: #d2d2d2;
}

.controle {
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
}

.controle button {
    padding: 0rem 0.55rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
}

.btn-audio {
    position: absolute;
    right: 44px;
    top: 1px;
    bottom: 1px;
    padding: 0 10px;
    border-radius: 50px;
    background-color: transparent;
    display: flex;
    border: none;
    align-items: center;
}

.btn-audio img {
    width: 24px;
}

#btnVoiceActivated {
    background-color: #56bb8f;
    transform: scale(1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(109, 218, 136, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(109, 219, 128, 0.4);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(110, 243, 105, 0.5);
    }
}

.btn-plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.btn-minus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.input-codigo,
.input-quantidade {
    display: flex;
    align-items: center;
    position: relative;
}

.input {
    margin-top: 5px;
    align-items: center;
}

.input-validade input,
.input-quantidade input {
    font-size: 15px;
    padding: 12px 8px;
    background-color: #fff;
}

.input-quantidade input {
    text-align: center;
    padding: 14px 8px;
}

.container {
    margin-top: 20px;
}

.containerQV {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
}

.repeatQV {
    background-color: #d2d2d2;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
}

.btn-scan {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    background-color: transparent;
    display: flex;
    border: none;
    align-items: center;
}

.mensagemErro {
    color: var(--vermelho);
    font-size: 0.7rem;
    margin-top: 5px;
}

.label {
    display: flex;
    align-items: center;
}

.label span {
    font-size: 16px;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 20%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 15px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--vermelho);
    color: #fff;
}

.remove-validade {
    position: absolute;
    display: flex;
    z-index: 1;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: #878787;
    padding: 2px;
    border: none;
    cursor: pointer;
}

.remove-validade img {
    width: 20px;
}

@media (max-width: 359px) {
    .label span {
        font-size: 14px;
    }

    input {
        font-size: 15px;
    }

    .nova-validade h3 {
        font-size: 13px;
    }

    .remove-validade img {
        width: 20px;
    }

    .tela-mensagem h3 {
        font-size: 15px;
    }

    .cancel-msg {
        font-size: 20px;
    }
}

@media (max-height: 510px) {
    .cancel-msg {
        font-size: 18px;
    }

    .cancel-btn {
        margin-top: 5%;
    }
}

@media (max-width: 327px) {
    .label span {
        font-size: 12px;
    }

    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }

    .tooltip-qnt {
        margin-left: 80px;
    }

    input {
        height: 45px;
    }

    .tooltip {
        display: none;
    }

    .repeatQV {
        padding: 5px;
    }
}

@media (max-width: 300px) {
    .containerQV {
        display: flex;
        flex-direction: column;
    }
}
</style>
